import * as React from 'react';
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useSearchParams } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import {firestore} from './firebaseConfig';
import { Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const theme = createTheme({

  palette: {

    primary: {

      main: '#000000',

    },

    secondary: {

      main: '#000000', light: '#FFFFFF', contrastText: '#212121',

    },

  },

});

function PaymentSuccess() {

  const searchQuery = useSearchParams()[0];
  const paymentId = searchQuery.get('paymentId');

  const [cartItems, setCartItems] = useState([]);
  const [form, setForm] = useState([]);
  
  useEffect(() => {
    
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(savedCartItems);

  }, []);
  
  //useEffect(() => {
  //  
  //  localStorage.setItem('cartItems', JSON.stringify(cartItems));
//
  //  const uploadToFirestore = async () => {
//
  //    const docRef = doc(firestore, "Orders", paymentId);
  //
  //    try {
//
  //      await setDoc(docRef, { cartItems });
//
  //      console.log(cartItems)
  //      console.log('Cart Items Uploaded To Firestore Successfully');
//
  //    } catch (error) {
//
  //      console.error('Error Uploading Cart Items To Firestore: ', error);
//
  //    }
//
  //  };
  //
  //  uploadToFirestore();
  //
  //}, [cartItems]);

  useEffect(() => {
    
    const forms = JSON.parse(localStorage.getItem('forms')) || [];
    setForm(forms);
  
  }, []);
  
  //useEffect(() => {
  //  
  //  localStorage.setItem('forms', JSON.stringify(form));
//
  //  const uploadToFirestore = async () => {
//
  //    const docRef = doc(firestore, "Orders", paymentId);
  //
  //    try {
//
  //      await setDoc(docRef, { form });
//
  //      console.log(form)
  //      console.log('Form Uploaded To Firestore Successfully');
//
  //    } catch (error) {
//
  //      console.error('Error Uploading Form To Firestore: ', error);
//
  //    }
//
  //  };
  //
  //  uploadToFirestore();
  //
  //}, [form]);

    return(
    
    <ThemeProvider theme={theme}>
      
      <AppBar sx={{ position: 'fixed' }}>
        
        <Toolbar>
          
          <IconButton edge="start" color="inherit" component={Link} to="/">
            
            <CloseIcon />
            
          </IconButton>
          
        </Toolbar>

      </AppBar>
      
      <Box margin={2}>
        
        <Box margin={10} />
        
        <Typography variant="h5" align='center'>Thank you.</Typography>
        <Typography variant="body1" align='center'>Order ID - {paymentId}</Typography>
        <Typography variant="body2" align='center'>We are processing your order and will send you a confirmation shortly.</Typography>
        
      </Box>
      
    </ThemeProvider>

    );

}

export default PaymentSuccess;
