import * as React from 'react';
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { firestore } from "./firebaseConfig";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel, CarouselItem } from 'react-bootstrap';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { styled, Box, CssBaseline, Paper, BottomNavigation, BottomNavigationAction, Fab, Grid, Card, CardMedia, CardContent, Typography, Stack, AppBar, Toolbar, IconButton, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Button, TextField } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import ManIcon from '@mui/icons-material/Man'
import WomanIcon from '@mui/icons-material/Woman'
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close'
import StarIcon from '@mui/icons-material/Star'
import Slide from '@mui/material/Slide';
import axios from 'axios';
import Logo from './logo.png'

const theme = createTheme({

  palette: {

    primary: {

      main: '#000000',

    },

    secondary: {

      main: '#000000', light: '#FFFFFF', contrastText: '#212121',

    },

  },

});

const Transition = React.forwardRef(function Transition(props, ref) {

  return <Slide direction="up" ref={ref} {...props} />;

});

const StyledCardContent = styled(CardContent)`paddingBottom: 0; &: last-child{padding-bottom: 0;}`;

function Home() {

  const [value, setValue] = React.useState(0);

  const [data1, setData1] = useState([]);

  useEffect(()=>{

    const fetchData = async()=>{

      const querySnapshot = await getDocs (collection(firestore, "Home-1"));
      const data = querySnapshot.docs.map((doc)=>doc.data());
      setData1(data);

  };
  
  fetchData ();

  }, []);

  const [data2, setData2] = useState([]);

  useEffect(()=>{

    const fetchData = async()=>{

      const querySnapshot = await getDocs (collection(firestore, "Home-2"));
      const data = querySnapshot.docs.map((doc)=>doc.data());
      setData2(data);

  };
  
  fetchData ();

  }, []);

  const [data3, setData3] = useState([]);

  useEffect(()=>{

    const fetchData = async()=>{

      const querySnapshot = await getDocs (collection(firestore, "Home-3"));
      const data = querySnapshot.docs.map((doc)=>doc.data());
      setData3(data);

  };
  
  fetchData ();

  }, []);

  const [data4, setData4] = useState([]);

  useEffect(()=>{

    const fetchData = async()=>{

      const querySnapshot = await getDocs (collection(firestore, "Home-4"));
      const data = querySnapshot.docs.map((doc)=>doc.data());
      setData4(data);

  };
  
  fetchData ();

  }, []);

  const [open, setOpen] = React.useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [showButton, setShowButton] = useState(false);

  const handleOpen = async (type, sku) => {

    setOpen(true);
    const docRef = doc(collection(firestore, type), sku);
    const docSnapshot = await getDoc(docRef);
    setDocumentData(docSnapshot.data());
    const dataArrayRef = await getDoc(doc(firestore, type, sku));

    if (dataArrayRef.exists()) {
      
      const imageUrls = dataArrayRef.data().imageUrls;
      const urls = imageUrls.map(url => ({
        
        original: url,
        thumbnail: url
      
      }));

      setImageUrl(urls[0].original.toString())
      setImageUrls(urls);

    }

    if (docSnapshot.exists()) {

      setShowButton(docSnapshot.data().size);

    }

  }

  const handleClose = () => {

    setOpen(false);

  };

  const [selectedValue, setSelectedValue] = useState('');

  const handleRadio = (event) => {

    setSelectedValue(event.target.value);

  };

  const [cartItems, setCartItems] = useState([]);
  const [form, setForm] = useState([]);
  
  useEffect(() => {
    
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(savedCartItems);
  
  }, []);
  
  useEffect(() => {
    
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  
  }, [cartItems]);

  const removeItem = (index) => {

    const newCartItems = [...cartItems];
    newCartItems.splice(index, 1);
    setCartItems(newCartItems);
  
  };

  useEffect(() => {
    
    const forms = JSON.parse(localStorage.getItem('forms')) || [];
    setForm(forms);
  
  }, []);
  
  useEffect(() => {
    
    localStorage.setItem('forms', JSON.stringify(form));
  
  }, [form]);

  const handleButton1 = (item) => {

    setCartItems([...cartItems, item]);
    
  };
  
  const handleButton2 = (item) => {

    setForm([...cartItems, item]);
    
  };

  const handleCombinedClick1 = (sku, item, imageUrl, price1, price2) => {

    handleButton1({ id: sku, title: item, image: imageUrl, price1: price1, price2: price2 });
    handleClose();

  };

  const handleCombinedClick2 = (sku, item, imageUrl, price1, price2, size) => {

    if (selectedValue !== '') {
      
      handleButton1({ id: sku, title: item, image: imageUrl, price1: price1, price2: price2, size: size });
      handleClose();

    } else if(selectedValue === '') {

      alert("Please Select Size");

    }
    
  };
  
  const [totalPrice, setTotalPrice] = useState(0);
  const freeDeliveryThreshold = 499;
  const [remainingForFreeDelivery, setRemainingForFreeDelivery] = useState(0);
  
  useEffect(() => {
    
    const totalPrice = cartItems.reduce((total, item) => total + parseFloat(item.price1), 0);
    setTotalPrice(totalPrice);
    const remaining = Math.max(freeDeliveryThreshold - totalPrice, 0);
    setRemainingForFreeDelivery(remaining);
  
  }, [cartItems]);

  const [ state1, setState1 ] = React.useState ( { top : false, left : false, bottom : false, right : false } );

  const [ state2, setState2 ] = React.useState ( { top : false, left : false, bottom : false, right : false } );

  const [ state3, setState3 ] = React.useState ( { top : false, left : false, bottom : false, right : false } );

  const toggleDrawer1 = ( anchor, open ) => ( event ) => {

    if ( event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' ) ) {

      return;

    }

    setState1 ( { ... state1, [ anchor ] : open } );

  };

  const toggleDrawer2 = ( anchor, open ) => ( event ) => {

    if ( event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' ) ) {

      return;

    }

    setState2 ( { ... state2, [ anchor ] : open } );

  };

  const toggleDrawer3 = ( anchor, open ) => ( event ) => {

    if ( event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' ) ) {

      return;

    }

    setState3 ( { ... state3, [ anchor ] : open } );

  };

  const list1 = ( anchor ) => (

    <Box sx = { { width : anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 } } role = "presentation" onClick = { toggleDrawer1 ( anchor, false ) } onKeyDown = { toggleDrawer1 ( anchor, false ) } >

      <List subheader = { <ListSubheader>Apparels</ListSubheader> } >
        
        <ListItemButton component={Link} to="/mtw">
          
          <ListItemText primary="Top Wear" />

        </ListItemButton>

        <ListItemButton component={Link} to="/mbw">
          
          <ListItemText primary="Bottom Wear" />

        </ListItemButton>

        <ListItemButton component={Link} to="/mfa">
          
          <ListItemText primary="Fashion Accessories" />

        </ListItemButton>

        <ListItemButton component={Link} to="/ms">
          
          <ListItemText primary="Sunglasses" />

        </ListItemButton>

        <ListItemButton component={Link} to="/mao">
          
          <ListItemText primary="Add Ons" />

        </ListItemButton>

      </List>

      <List subheader = { <ListSubheader>Fragnances</ListSubheader> } >
        
        <ListItemButton component={Link} to="/mp">
          
          <ListItemText primary="Perfumes" />

        </ListItemButton>

        <ListItemButton component={Link} to="/ma">
          
          <ListItemText primary="Attar" />

        </ListItemButton>

      </List>

      <List subheader = { <ListSubheader>Wearables</ListSubheader> } >
        
        <ListItemButton component={Link} to="/maw">
          
          <ListItemText primary="Analog Watches" />

        </ListItemButton>

      </List>

      <List subheader = { <ListSubheader>Smart Gadgets</ListSubheader> } >
        
        <ListItemButton component={Link} to="/map">
          
          <ListItemText primary="Airpods" />

        </ListItemButton>

        <ListItemButton component={Link} to="/msw">
          
          <ListItemText primary="Smart Watches" />

        </ListItemButton>

      </List>

    </Box>

  );

  const list2 = ( anchor ) => (

    <Box sx = { { width : anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 } } role = "presentation" onClick = { toggleDrawer2 ( anchor, false ) } onKeyDown = { toggleDrawer2 ( anchor, false ) } >

      <List subheader = { <ListSubheader>Apparels</ListSubheader> } >
        
        <ListItemButton component={Link} to="/wtw">
          
          <ListItemText primary="Top Wear" />

        </ListItemButton>

        <ListItemButton component={Link} to="/wbw">
          
          <ListItemText primary="Bottom Wear" />

        </ListItemButton>

        <ListItemButton component={Link} to="/wfa">
          
          <ListItemText primary="Fashion Accessories" />

        </ListItemButton>

        <ListItemButton component={Link} to="/ws">
          
          <ListItemText primary="Sunglasses" />

        </ListItemButton>

        <ListItemButton component={Link} to="/wao">
          
          <ListItemText primary="Add Ons" />

        </ListItemButton>

      </List>

      <List subheader = { <ListSubheader>Fragnances</ListSubheader> } >
        
        <ListItemButton component={Link} to="/wp">
          
          <ListItemText primary="Perfumes" />

        </ListItemButton>

        <ListItemButton component={Link} to="/wa">
          
          <ListItemText primary="Attar" />

        </ListItemButton>

      </List>

      <List subheader = { <ListSubheader>Wearables</ListSubheader> } >
        
        <ListItemButton component={Link} to="/waw">
          
          <ListItemText primary="Analog Watches" />

        </ListItemButton>

      </List>

      <List subheader = { <ListSubheader>Smart Gadgets</ListSubheader> } >
        
        <ListItemButton component={Link} to="/wap">
          
          <ListItemText primary="Airpods" />

        </ListItemButton>

        <ListItemButton component={Link} to="/wsw">
          
          <ListItemText primary="Smart Watches" />

        </ListItemButton>

      </List>

    </Box>

  );

  const PAYMENT_HOST = process.env.REACT_APP_PAYMENT_HOST;

  const handlePayment = async amount => {

    const { data : { order } } = await axios.post(`${PAYMENT_HOST}/payments/checkout`, { amount });

    const options = {

      key: process.env.REACT_APP_API_KEY,
      amount: amount,
      currency: 'INR',
      name: 'Style Indo',
      order_id: order.id,
      callback_url: `${PAYMENT_HOST}/payments/verify`,

    };

    var razorpay = new window.Razorpay(options);
    razorpay.open();

  };

  const [openD1, setOpenD1] = useState(false);

  const [openD2, setOpenD2] = useState(false);

  const handleForm = (y1, y2, y3, y4, y5, y6, y7, y8) => {

    handleButton2({ x1: y1, x2: y2, x3: y3, x4: y4, x5: y5, x6: y6, x7: y7, x8: y8 });

  };
  
  const [formData1, setFormData1] = useState({
    
    firstName: '',
    lastName: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    pinCode: '',
    city: '',
    state: ''
  
  });

  const handleChange1 = (e) => {

    setFormData1({ ...formData1, [e.target.name]: e.target.value });
  
  };

  const handleSubmit1 = () => {
    
    if (

      formData1.firstName.trim() === '' ||
      formData1.lastName.trim() === '' ||
      formData1.phoneNumber.trim() === '' ||
      formData1.addressLine1.trim() === '' ||
      formData1.addressLine2.trim() === '' ||
      formData1.pinCode.trim() === '' ||
      formData1.city.trim() === '' ||
      formData1.state.trim() === ''
      
      ) {
        
        alert('Please Fill In All Required Fields.');
        return;
      
      }
      
      setOpenD1(false);
      handleForm(
        formData1.firstName,
        formData1.lastName,
        formData1.phoneNumber,
        formData1.addressLine1,
        formData1.addressLine2,
        formData1.pinCode,
        formData1.city,
        formData1.state
      )
      handlePayment(totalPrice + 99);
    
  };

  const [formData2, setFormData2] = useState({
    
    firstName: '',
    lastName: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    pinCode: '',
    city: '',
    state: ''
  
  });

  const handleChange2 = (e) => {

    setFormData2({ ...formData2, [e.target.name]: e.target.value });
  
  };

  const handleSubmit2 = () => {
    
    if (

      formData2.firstName.trim() === '' ||
      formData2.lastName.trim() === '' ||
      formData2.phoneNumber.trim() === '' ||
      formData2.addressLine1.trim() === '' ||
      formData2.addressLine2.trim() === '' ||
      formData2.pinCode.trim() === '' ||
      formData2.city.trim() === '' ||
      formData2.state.trim() === ''
      
      ) {
        
        alert('Please Fill In All Required Fields.');
        return;
      
      }
      
      setOpenD2(false);
      handleForm(
        formData2.firstName,
        formData2.lastName,
        formData2.phoneNumber,
        formData2.addressLine1,
        formData2.addressLine2,
        formData2.pinCode,
        formData2.city,
        formData2.state
      )
      handlePayment(totalPrice);

  };

  return (

    <ThemeProvider theme={theme}>

      <Carousel>
        
        <CarouselItem>
        
        <img className="d-block w-100" src="https://firebasestorage.googleapis.com/v0/b/style-indo-101.appspot.com/o/Banner%2FBanner_1.png?alt=media&token=82669ac6-31f9-400d-aea8-553db36e6fe3"/>
        
        </CarouselItem>

      </Carousel>
      
      <Box>
        
        <CssBaseline />
        
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={5}>
          
          <BottomNavigation showLabels value={value} onChange={(event, newValue) => {setValue(newValue);}}>
            
            <BottomNavigationAction label="Man" icon={<ManIcon />} onClick = { toggleDrawer1 ( 'bottom', true )} />
            <img margin={5} src={Logo} />
            <BottomNavigationAction label="Women" icon={<WomanIcon />} onClick = { toggleDrawer2 ( 'bottom', true )} />
            
          </BottomNavigation>
          
        </Paper>

      { [ 'left', 'right', 'top', 'bottom' ] .map ( ( anchor ) => (
      
      <React.Fragment key = { anchor } >
        
        <Drawer anchor = { anchor } open = { state1 [ anchor ] } onClose = { toggleDrawer1 ( anchor, false ) } >
          
          { list1 ( anchor ) }
          
          </Drawer>
          
          </React.Fragment>
          
          ) ) }
      
      { [ 'left', 'right', 'top', 'bottom' ] .map ( ( anchor ) => (
      
      <React.Fragment key = { anchor } >
        
        <Drawer anchor = { anchor } open = { state2 [ anchor ] } onClose = { toggleDrawer2 ( anchor, false ) } >
          
          { list2 ( anchor ) }
          
          </Drawer>
          
          </React.Fragment>
          
          ) ) }
          
      { [ 'left', 'right', 'top', 'bottom' ] .map ( ( anchor ) => (
      
      <React.Fragment key = { anchor } >
        
        <Drawer anchor = { anchor } open = { state3 [ anchor ] } onClose = { toggleDrawer3 ( anchor, false ) } >

        <Box sx = { { width : anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 } } role = "presentation" onKeyDown = { toggleDrawer3 ( anchor, false ) } >
          
          <Stack>
            
            {cartItems.map((item, index) => (
            
            <Grid sx={{marginTop: 0.25, marginBottom: 0.25}}item key={index}>
              
              <Card sx={{display: 'flex', maxHeight: 250, marginLeft: 0.5, marginRight: 0.5, marginTop: 0.25, marginBottom: 0.25}}>
                
                <CardMedia sx={{height: 100, width: 100}} image={item.image} />
                
                <StyledCardContent>
                  
                  <Stack direction="row">
                    
                  <Typography variant="body2">{item.title}</Typography>
                    
                  <Box margin={0.2} />
                    
                  <Typography variant="body2" color="textSecondary">{item.size}</Typography>
                    
                  </Stack>
                    
                  <Stack direction="row">
                      
                  <Typography variant="body2" style={{ fontWeight: 600 }}>₹{item.price1}</Typography>
                    
                  <Box margin={0.2} />
                    
                  <Typography variant="body2" color="textSecondary"><p><s>₹{item.price2}</s></p></Typography>
                    
                  </Stack>

                  <Typography variant="caption" color="textSecondary" onClick={() => removeItem(index)}>Remove</Typography>
                  
                </StyledCardContent>
                
              </Card>
              
            </Grid>
            
            ))}
            
            <Paper sx={{ position: 'fixed', bottom: 0, padding: 1 }} elevation={5}>
              
              <Stack direction="row">
              
              <Typography variant="caption">Total Price - </Typography>

              <Box margin={0.2} />

              <Typography variant="caption">₹{totalPrice}/-</Typography>

              </Stack>

              <Stack direction="column">
              
              {totalPrice < freeDeliveryThreshold ? (
              
              <Stack direction="column">
              
              <Typography variant="caption">Delivery Charges - ₹99/-</Typography>
              
              <Typography variant="caption" color="textSecondary">Total Price Is Less Than ₹{freeDeliveryThreshold}. You Need ₹{remainingForFreeDelivery} More For Free Delivery.</Typography>

              <Box margin={0.5} />

              <Stack direction="row">
              
              <Typography variant="body1" style={{ fontWeight: 600 }}>Final Price - </Typography>

              <Box margin={0.2} />

              {totalPrice == 0 ? (

              <Typography variant="body1" style={{ fontWeight: 600 }}>₹{totalPrice}/-</Typography>

              ) : (
              
              <Typography variant="body1" style={{ fontWeight: 600 }}>₹{totalPrice+99}/-</Typography>

              )}

              </Stack>

              <Box margin={1} />

              <Button variant='contained' onClick={() => setOpenD1(true)}>Place Order</Button>

              </Stack>
              
              ) : (
              
              <Stack direction="column">

              <Typography variant="caption">Delivery Charges - ₹0/-</Typography>
              
              <Typography variant="caption" color="textSecondary">Total Price Is ₹{totalPrice}. You Qualify For Free Delivery!</Typography>

              <Stack direction="row">
              
              <Typography variant="caption">Final Price - </Typography>

              <Box margin={0.2} />

              <Typography variant="caption">₹{totalPrice}/-</Typography>

              </Stack>

              <Button variant='contained' onClick={() => setOpenD2(true)}>Place Order</Button>

              </Stack>
              
              )}

              </Stack>
              
            </Paper>
            
          </Stack>

          <Box margin={20} />

        </Box>
          
        </Drawer>
          
      </React.Fragment>

      ) ) }

        <Fab sx={{ position: 'fixed', top: `calc(100% - ${122.5}px)`, left: `calc(100% - ${70}px)` }} color="primary" onClick = { toggleDrawer3 ( 'right', true )}>

          <ShoppingCart />

        </Fab>

        <Typography variant="h5" margin={2} align='center'>New Arrivals</Typography>

        <Grid container spacing={0} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>

        {data1.map((item)=>(

          <Card sx={{minHeight: 250, maxHeight: 250, maxWidth: 150, marginLeft: 0.5, marginRight: 0.5, marginTop: 0.25, marginBottom: 0.25}} onClick={() =>{handleOpen("Home-1", item.sku, item.imageUrls[0])}}>

            <CardMedia sx={{height: 150, width: 150}} image={item.imageUrls[0]} />

            <StyledCardContent>

              <Typography variant="body2">{item.title}</Typography>

              <Stack direction="row">

              <Typography variant="body2" style={{ fontWeight: 600 }}>₹{item.price1}</Typography>
              
              <Box margin={0.2} />

              <Typography variant="body2" color="textSecondary"><p><s>₹{item.price2}</s></p></Typography>

              </Stack>

            </StyledCardContent>

          </Card>
          
        ))}

        </Grid>

        <Typography variant="h5" margin={2} align='center'>Trending Now</Typography>

        <Grid container spacing={0} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>

        {data2.map((item)=>(

          <Card sx={{minHeigh: 250, maxHeight: 250, maxWidth: 150, marginLeft: 0.5, marginRight: 0.5, marginTop: 0.25, marginBottom: 0.25}} onClick={() =>{handleOpen("Home-2", item.sku)}}>

            <CardMedia sx={{height: 150, width: 150}} image={item.imageUrls[0]} />

            <CardContent>

              <Typography variant="body2">{item.title}</Typography>

              <Stack direction="row">

              <Typography variant="body2" style={{ fontWeight: 600 }}>₹{item.price1}</Typography>
              
              <Box margin={0.2} />

              <Typography variant="body2" color="textSecondary"><p><s>₹{item.price2}</s></p></Typography>

              </Stack>

            </CardContent>

          </Card>
          
        ))}

        </Grid>

        <Typography variant="h5" margin={2} align='center'>Best Sellers</Typography>

        <Grid container spacing={0} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>

        {data3.map((item)=>(

          <Card sx={{minHeight: 250, maxHeight: 250, maxWidth: 150, marginLeft: 0.5, marginRight: 0.5, marginTop: 0.25, marginBottom: 0.25}} onClick={() =>{handleOpen("Home-3", item.sku)}}>

            <CardMedia sx={{height: 150, width: 150}} image={item.imageUrls[0]} />

            <CardContent>

              <Typography variant="body2">{item.title}</Typography>

              <Stack direction="row">

              <Typography variant="body2" style={{ fontWeight: 600 }}>₹{item.price1}</Typography>
              
              <Box margin={0.2} />

              <Typography variant="body2" color="textSecondary"><p><s>₹{item.price2}</s></p></Typography>

              </Stack>

            </CardContent>

          </Card>
          
        ))}

        </Grid>

        <Typography variant="h5" margin={2} align='center'>Value For Money</Typography>

        <Grid container spacing={0} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>

        {data4.map((item)=>(

          <Card sx={{minHeight: 250, maxHeight: 250, maxWidth: 150, marginLeft: 0.5, marginRight: 0.5, marginTop: 0.25, marginBottom: 0.25}} onClick={() =>{handleOpen("Home-4", item.sku)}}>

            <CardMedia sx={{height: 150, width: 150}} image={item.imageUrls[0]} />

            <CardContent>

              <Typography variant="body2">{item.title}</Typography>

              <Stack direction="row">

              <Typography variant="body2" style={{ fontWeight: 600 }}>₹{item.price1}</Typography>
              
              <Box margin={0.2} />

              <Typography variant="body2" color="textSecondary"><p><s>₹{item.price2}</s></p></Typography>

              </Stack>

            </CardContent>

          </Card>
          
        ))}

        </Grid>

        <Box margin={2} />

        <Paper sx={{ borderRadius: 0 }}>

          <Stack direction={'column'}>
            
            <Paper sx={{ borderRadius: 0, backgroundColor: 'black', padding: 1 }}>
              
              <Typography align='center' variant='h6' color={ 'white' }>STYLE INDO ©</Typography>
              
            </Paper>

            <Box sx={{ padding: 1 }}>

              <Stack direction={'row'} useFlexGap justifyContent={'center'}>
                
                <Typography variant='subtitle1' component={Link} to="/p1">About Us</Typography>

                <Box sx={{ padding: 1 }} />

                <Typography variant='subtitle1' component={Link} to="/p2">Contact Us</Typography>

              </Stack>

              <Stack direction={'row'} useFlexGap justifyContent={'center'}>
                
                <Typography variant='subtitle2' component={Link} to="/p3">Terms & Conditions</Typography>

                <Box sx={{ padding: 1 }} />
                
                <Typography variant='subtitle2' component={Link} to="/p4">Privacy Policy</Typography>

                <Box sx={{ padding: 1 }} />
                
                <Typography variant='subtitle2' component={Link} to="/p5">Legal Policy</Typography>

              </Stack>

              <Stack direction={'row'} useFlexGap justifyContent={'center'}>
                
                <Typography variant='subtitle2' component={Link} to="/p6">Shipping Policy</Typography>

                <Box sx={{ padding: 1 }} />
                
                <Typography variant='subtitle2' component={Link} to="/p7">Return Policy</Typography>

              </Stack>

              <Typography align='center' variant='subtitle2'>Career</Typography>

            </Box>

          </Stack>

        </Paper>

        <Box margin={7} />

        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          
          <AppBar sx={{ position: 'fixed' }}>
            
            <Toolbar>
              
              <IconButton edge="start" color="inherit" onClick={handleClose}>
                
                <CloseIcon />
                
              </IconButton>
              
            </Toolbar>
            
          </AppBar>

          {documentData && (

            <Box>
              
              <Box margin={6} />
              
              <ImageGallery items={imageUrls} autoPlay={true}/>

              <Box margin={2}>
                
                <Stack direction="row">
                  
                  <StarIcon sx={{ height: 16, width: 16, alignSelf: 'center' }} />
                  
                  <Box margin={0.2} />
                  
                  <Typography variant="body2" color="textSecondary">{documentData.rate}</Typography>
                  
                </Stack>
                
                <Typography variant="body4">{documentData.title}</Typography>
                
                <Stack direction="row">
                  
                  <Typography variant="body2" style={{ fontWeight: 600 }}>₹{documentData.price1}</Typography>
                  
                  <Box margin={0.2} />
                  
                  <Typography variant="body2" color="textSecondary"><p><s>₹{documentData.price2}</s></p></Typography>
                  
                </Stack>

                {showButton &&

                <FormControl>
                  
                  <FormLabel>Size</FormLabel>
                  
                  <RadioGroup row value={selectedValue} onChange={handleRadio}>

                    <FormControlLabel value="XS" control={<Radio size="small" />} label={<Typography variant="caption">XS</Typography>} />
                    <FormControlLabel value="S" control={<Radio size="small" />} label={<Typography variant="caption">S</Typography>} />
                    <FormControlLabel value="M" control={<Radio size="small" />} label={<Typography variant="caption">M</Typography>} />
                    <FormControlLabel value="L" control={<Radio size="small" />} label={<Typography variant="caption">L</Typography>} />
                    <FormControlLabel value="XL" control={<Radio size="small" />} label={<Typography variant="caption">XL</Typography>} />
                    
                  </RadioGroup>
                  
                </FormControl> 
                
                }

                <Typography variant="body2">{documentData.description}</Typography>

                <Box margin={10} />

              </Box>

            </Box>

          )}
          
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={5}>
            
            {!showButton &&
            
            <Button sx={{width: "100%"}} variant='contained' onClick={()=>handleCombinedClick1(documentData.sku, documentData.title, imageUrl, documentData.price1, documentData.price2)}>Add To Cart</Button>
            
            }
            
            {showButton &&
            
            <Button sx={{width: "100%"}} variant='contained' onClick={()=>handleCombinedClick2(documentData.sku, documentData.title, imageUrl, documentData.price1, documentData.price2, selectedValue)}>Add To Cart</Button>
            
            }

          </Paper>

        </Dialog>

        <Dialog open={openD1} onClose={() => setOpenD1(false)}>
          
          <DialogTitle>Place Order</DialogTitle>
          
          <DialogContent>
            
            <TextField autoFocus required margin="dense" label="First Name" type="text" name="firstName" fullWidth value={formData1.firstName} onChange={handleChange1} />
            <TextField autoFocus required margin="dense" label="Last Name" type="text" name="lastName" fullWidth value={formData1.lastName} onChange={handleChange1} />
            <TextField autoFocus required margin="dense" label="Phone Number" type="number" name="phoneNumber" fullWidth value={formData1.phoneNumber} onChange={handleChange1} />
            <TextField autoFocus required margin="dense" label="Address Line 1" type="text" name="addressLine1" fullWidth value={formData1.addressLine1} onChange={handleChange1} />
            <TextField autoFocus required margin="dense" label="Address Line 2" type="text" name="addressLine2" fullWidth value={formData1.addressLine2} onChange={handleChange1} />
            <TextField autoFocus required margin="dense" label="Pin Code" type="number" name="pinCode" fullWidth value={formData1.pinCode} onChange={handleChange1} />
            <TextField autoFocus required margin="dense" label="City" type="text" name="city" fullWidth value={formData1.city} onChange={handleChange1} />
            <TextField autoFocus required margin="dense" label="State" type="text" name="state" fullWidth value={formData1.state} onChange={handleChange1} />
            
          </DialogContent>
                
          <DialogActions>
            
            <Button onClick={() => setOpenD1(false)}>Cancel</Button>
            
            <Button onClick={handleSubmit1}>Pay Now</Button>
            
          </DialogActions>
          
        </Dialog>

        <Dialog open={openD2} onClose={() => setOpenD2(false)}>
          
          <DialogTitle>Place Order</DialogTitle>
          
          <DialogContent>
            
            <TextField autoFocus required margin="dense" label="First Name" type="text" name="firstName" fullWidth value={formData2.firstName} onChange={handleChange2} />
            <TextField autoFocus required margin="dense" label="Last Name" type="text" name="lastName" fullWidth value={formData2.lastName} onChange={handleChange2} />
            <TextField autoFocus required margin="dense" label="Phone Number" type="number" name="phoneNumber" fullWidth value={formData2.phoneNumber} onChange={handleChange2} />
            <TextField autoFocus required margin="dense" label="Address Line 1" type="text" name="addressLine1" fullWidth value={formData2.addressLine1} onChange={handleChange2} />
            <TextField autoFocus required margin="dense" label="Address Line 2" type="text" name="addressLine2" fullWidth value={formData2.addressLine2} onChange={handleChange2} />
            <TextField autoFocus required margin="dense" label="Pin Code" type="number" name="pinCode" fullWidth value={formData2.pinCode} onChange={handleChange2} />
            <TextField autoFocus required margin="dense" label="City" type="text" name="city" fullWidth value={formData2.city} onChange={handleChange2} />
            <TextField autoFocus required margin="dense" label="State" type="text" name="state" fullWidth value={formData2.state} onChange={handleChange2} />
            
          </DialogContent>
                
          <DialogActions>
            
            <Button onClick={() => setOpenD2(false)}>Cancel</Button>
            
            <Button onClick={handleSubmit2}>Pay Now</Button>
            
          </DialogActions>
          
        </Dialog>

      </Box>

    </ThemeProvider>

  );
  
}

export default Home;
