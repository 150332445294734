import * as React from 'react';
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { firestore } from "./firebaseConfig";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { styled, Box, Paper, Fab, Grid, Card, CardMedia, CardContent, Typography, Stack, Dialog, AppBar, Toolbar, IconButton, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Button } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close'
import BackIcon from '@mui/icons-material/ArrowBack'
import StarIcon from '@mui/icons-material/Star'
import Slide from '@mui/material/Slide';

const theme = createTheme({

  palette: {

    primary: {

      main: '#000000',

    },

    secondary: {

      main: '#000000', light: '#FFFFFF', contrastText: '#212121',

    },

  },

});

const Transition = React.forwardRef(function Transition(props, ref) {

  return <Slide direction="up" ref={ref} {...props} />;

});

const StyledCardContent = styled(CardContent)`paddingBottom: 0; &: last-child{padding-bottom: 0;}`;

function WP() {

  const [data1, setData1] = useState([]);

  useEffect(()=>{

    const fetchData = async()=>{

      const querySnapshot = await getDocs (collection(firestore, "WP"));
      const data = querySnapshot.docs.map((doc)=>doc.data());
      setData1(data);

  };
  
  fetchData ();

  }, []);

  const [open, setOpen] = React.useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [showButton, setShowButton] = useState(false);

  const handleOpen = async (type, sku) => {

    setOpen(true);
    const docRef = doc(collection(firestore, type), sku);
    const docSnapshot = await getDoc(docRef);
    setDocumentData(docSnapshot.data());
    const dataArrayRef = await getDoc(doc(firestore, type, sku));

    if (dataArrayRef.exists()) {
      
      const imageUrls = dataArrayRef.data().imageUrls;
      const urls = imageUrls.map(url => ({
        
        original: url,
        thumbnail: url
      
      }));

      setImageUrl(urls[0].original.toString())
      setImageUrls(urls);

    }

    if (docSnapshot.exists()) {

      setShowButton(docSnapshot.data().size);

    }

  }

  const handleClose = () => {

    setOpen(false);

  };

  const [selectedValue, setSelectedValue] = useState('');

  const handleRadio = (event) => {

    setSelectedValue(event.target.value);

  };

  const [cartItems, setCartItems] = useState([]);
  
  useEffect(() => {
    
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(savedCartItems);
  
  }, []);
  
  useEffect(() => {
    
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  
  }, [cartItems]);

  const removeItem = (index) => {

    const newCartItems = [...cartItems];
    newCartItems.splice(index, 1);
    setCartItems(newCartItems);
  
  };

  const handleButton = (item) => {

    setCartItems([...cartItems, item]);
    
  };

  const handleCombinedClick1 = (item, imageUrl, price1, price2) => {

    handleButton({ title: item, image: imageUrl, price1: price1, price2: price2 });
    handleClose();

  };

  const handleCombinedClick2 = (item, imageUrl, price1, price2, size) => {

    if (selectedValue !== '') {
      
      handleButton({ title: item, image: imageUrl, price1: price1, price2: price2, size: size });
      handleClose();

    } else if(selectedValue === '') {

      alert("Please Select Size");

    }
    
  };
  
  const [totalPrice, setTotalPrice] = useState(0);
  const freeDeliveryThreshold = 499;
  const [remainingForFreeDelivery, setRemainingForFreeDelivery] = useState(0);
  
  useEffect(() => {
    
    const totalPrice = cartItems.reduce((total, item) => total + parseFloat(item.price1), 0);
    setTotalPrice(totalPrice);
    const remaining = Math.max(freeDeliveryThreshold - totalPrice, 0);
    setRemainingForFreeDelivery(remaining);
  
  }, [cartItems]);

  const [ state3, setState3 ] = React.useState ( { top : false, left : false, bottom : false, right : false } );

  const toggleDrawer3 = ( anchor, open ) => ( event ) => {

    if ( event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' ) ) {

      return;

    }

    setState3 ( { ... state3, [ anchor ] : open } );

  };

  return (

    <ThemeProvider theme={theme}>
      
      <AppBar sx={{ position: 'fixed' }}>
        
        <Toolbar>
          
          <IconButton edge="start" color="inherit" component={Link} to="/">
            
            <BackIcon />
            
          </IconButton>

          <Typography>Women's Perfumes</Typography>
          
        </Toolbar>
            
      </AppBar>

    <Box>

    <Box margin={10} />

    <Grid container spacing={0} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>

        {data1.map((item)=>(

          <Card sx={{minHeight: 250, maxHeight: 250, maxWidth: 150, marginLeft: 0.5, marginRight: 0.5, marginTop: 0.25, marginBottom: 0.25}} onClick={() =>{handleOpen("WP", item.sku, item.imageUrls[0])}}>

            <CardMedia sx={{height: 150, width: 150}} image={item.imageUrls[0]} />

            <StyledCardContent>

              <Typography variant="body2">{item.title}</Typography>

              <Stack direction="row">

              <Typography variant="body2" style={{ fontWeight: 600 }}>₹{item.price1}</Typography>
              
              <Box margin={0.2} />

              <Typography variant="body2" color="textSecondary"><p><s>₹{item.price2}</s></p></Typography>

              </Stack>

            </StyledCardContent>

          </Card>
          
        ))}

    </Grid>

    { [ 'left', 'right', 'top', 'bottom' ] .map ( ( anchor ) => (
      
      <React.Fragment key = { anchor } >
        
        <Drawer anchor = { anchor } open = { state3 [ anchor ] } onClose = { toggleDrawer3 ( anchor, false ) } >

        <Box sx = { { width : anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 } } role = "presentation" onKeyDown = { toggleDrawer3 ( anchor, false ) } >
          
          <Stack>
            
            {cartItems.map((item, index) => (
            
            <Grid sx={{marginTop: 0.25, marginBottom: 0.25}}item key={index}>
              
              <Card sx={{display: 'flex', maxHeight: 250, marginLeft: 0.5, marginRight: 0.5, marginTop: 0.25, marginBottom: 0.25}}>
                
                <CardMedia sx={{height: 100, width: 100}} image={item.image} />
                
                <StyledCardContent>
                  
                  <Stack direction="row">
                    
                  <Typography variant="body2">{item.title}</Typography>
                    
                  <Box margin={0.2} />
                    
                  <Typography variant="body2" color="textSecondary">{item.size}</Typography>
                    
                  </Stack>
                    
                  <Stack direction="row">
                      
                  <Typography variant="body2" style={{ fontWeight: 600 }}>₹{item.price1}</Typography>
                    
                  <Box margin={0.2} />
                    
                  <Typography variant="body2" color="textSecondary"><p><s>₹{item.price2}</s></p></Typography>
                    
                  </Stack>

                  <Typography variant="caption" color="textSecondary" onClick={() => removeItem(index)}>Remove</Typography>
                  
                </StyledCardContent>
                
              </Card>
              
            </Grid>
            
            ))}
            
            <Paper sx={{ position: 'fixed', bottom: 0, padding: 1 }} elevation={5}>
              
              <Stack direction="row">
              
              <Typography variant="caption">Total Price - </Typography>

              <Box margin={0.2} />

              <Typography variant="caption">₹{totalPrice}/-</Typography>

              </Stack>

              <Stack direction="column">
              
              {totalPrice < freeDeliveryThreshold ? (
              
              <Stack direction="column">
              
              <Typography variant="caption">Delivery Charges - ₹99/-</Typography>
              
              <Typography variant="caption" color="textSecondary">Total Price Is Less Than ₹{freeDeliveryThreshold}. You Need ₹{remainingForFreeDelivery} More For Free Delivery.</Typography>

              <Box margin={0.5} />

              <Stack direction="row">
              
              <Typography variant="body1" style={{ fontWeight: 600 }}>Final Price - </Typography>

              <Box margin={0.2} />

              {totalPrice == 0 ? (

              <Typography variant="body1" style={{ fontWeight: 600 }}>₹{totalPrice}/-</Typography>

              ) : (
              
              <Typography variant="body1" style={{ fontWeight: 600 }}>₹{totalPrice+99}/-</Typography>

              )}

              </Stack>

              <Box margin={1} />

              <Button variant='contained'>Check Out</Button>

              </Stack>
              
              ) : (
              
              <Stack direction="column">

              <Typography variant="caption">Delivery Charges - ₹0/-</Typography>
              
              <Typography variant="caption" color="textSecondary">Total Price Is ₹{totalPrice}. You Qualify For Free Delivery!</Typography>

              <Stack direction="row">
              
              <Typography variant="caption">Final Price - </Typography>

              <Box margin={0.2} />

              <Typography variant="caption">₹{totalPrice}/-</Typography>

              </Stack>

              <Button variant='contained'>Check Out</Button>

              </Stack>
              
              )}

              </Stack>
              
            </Paper>
            
          </Stack>

          <Box margin={20} />

        </Box>
          
        </Drawer>
          
      </React.Fragment>

    ) ) }

    <Fab sx={{ position: 'fixed', top: `calc(100% - ${70}px)`, left: `calc(100% - ${70}px)` }} color="primary" onClick = { toggleDrawer3 ( 'right', true )}>
      
      <ShoppingCart />
      
    </Fab>

    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      
      <AppBar sx={{ position: 'fixed' }}>
        
        <Toolbar>
          
          <IconButton edge="start" color="inherit" onClick={handleClose}>
            
            <CloseIcon />
            
          </IconButton>
          
        </Toolbar>
            
      </AppBar>
      
      {documentData && (
      
      <Box>

<Box margin={6} />
        
        <ImageGallery items={imageUrls} autoPlay={true}/>
        
        <Box margin={2}>

        <Stack direction="row">
                  
                  <StarIcon sx={{ height: 16, width: 16, alignSelf: 'center' }} />
                        
                  <Box margin={0.2} />
                        
                  <Typography variant="body2" color="textSecondary">{documentData.rate}</Typography>
                        
                </Stack>
          
          <Typography variant="body4">{documentData.title}</Typography>
          
          <Stack direction="row">
            
            <Typography variant="body2" style={{ fontWeight: 600 }}>₹{documentData.price1}</Typography>
            
            <Box margin={0.2} />
            
            <Typography variant="body2" color="textSecondary"><p><s>₹{documentData.price2}</s></p></Typography>
            
          </Stack>
          
          {showButton &&
          
          <FormControl>
            
            <FormLabel>Size</FormLabel>
            
            <RadioGroup row value={selectedValue} onChange={handleRadio}>
              
              <FormControlLabel value="XS" control={<Radio size="small" />} label={<Typography variant="caption">XS</Typography>} />
              <FormControlLabel value="S" control={<Radio size="small" />} label={<Typography variant="caption">S</Typography>} />
              <FormControlLabel value="M" control={<Radio size="small" />} label={<Typography variant="caption">M</Typography>} />
              <FormControlLabel value="L" control={<Radio size="small" />} label={<Typography variant="caption">L</Typography>} />
              <FormControlLabel value="XL" control={<Radio size="small" />} label={<Typography variant="caption">XL</Typography>} />
              
            </RadioGroup>
                  
          </FormControl> 
          
          }
          
          <Typography variant="body2">{documentData.description}</Typography>
          
          <Box margin={10} />
          
        </Box>

      </Box>

      )}
      
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={5}>
        
        {!showButton &&
        
        <Button sx={{width: "100%"}} variant='contained' onClick={()=>handleCombinedClick1(documentData.title, imageUrl, documentData.price1, documentData.price2)}>Add To Cart</Button>
        
        }
            
        {showButton &&
            
        <Button sx={{width: "100%"}} variant='contained' onClick={()=>handleCombinedClick2(documentData.title, imageUrl, documentData.price1, documentData.price2, selectedValue)}>Add To Cart</Button>
            
        }
        
      </Paper>
      
    </Dialog>
    
    </Box>
    
    </ThemeProvider>

  )

}

export default WP
