import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material'
import BackIcon from '@mui/icons-material/ArrowBack'

const theme = createTheme({

  palette: {

    primary: {

      main: '#000000',

    },

    secondary: {

      main: '#000000', light: '#FFFFFF', contrastText: '#212121',

    },

  },

});

function P2() {

  return(

  <ThemeProvider theme={theme}>

    <AppBar sx={{ position: 'fixed' }}>

      <Toolbar>

        <IconButton edge="start" color="inherit" component={Link} to="/">

          <BackIcon />

        </IconButton>

        <Typography>Contact Us</Typography>

      </Toolbar>

    </AppBar>

    <Box margin={2}>

      <Box margin={10} />

      <Typography variant='body2' gutterBottom fontWeight={600}>Email</Typography>
      <Typography variant='body2' gutterBottom>infostyleindo@gmail.com</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Contact Number</Typography>
      <Typography variant='body2' gutterBottom>+91-9755766783</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Address</Typography>
      <Typography variant='body2' gutterBottom>HIG - HB/1, Abhiruchi Parisar, Old Subash Nagar, Bhopal, MP - 462023</Typography>

    </Box>

  </ThemeProvider>

  );

}

export default P2;
