import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material'
import BackIcon from '@mui/icons-material/ArrowBack'

const theme = createTheme({

  palette: {

    primary: {

      main: '#000000',

    },

    secondary: {

      main: '#000000', light: '#FFFFFF', contrastText: '#212121',

    },

  },

});

function P1() {

  return(

  <ThemeProvider theme={theme}>

    <AppBar sx={{ position: 'fixed' }}>

      <Toolbar>

        <IconButton edge="start" color="inherit" component={Link} to="/">

          <BackIcon />

        </IconButton>

        <Typography>About Us</Typography>

      </Toolbar>

    </AppBar>

    <Box margin={2}>

      <Box margin={10} />

      <Typography variant='body2' gutterBottom fontWeight={600}>Welcome to Style Indo - LET YOUR STYLE SPEAK!</Typography>
      <Typography variant='body2' gutterBottom>Hi, I'm Priyesh Ahirwar, the proud owner of Style Indo. With a passion for fashion, I embarked on this journey in 2024, bringing over 7 years of experience in the industry to create a unique and vibrant online platform.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Our Story</Typography>
      <Typography variant='body2' gutterBottom>At Style Indo, we believe that fashion is more than just clothing; it's an expression of individuality and style. Having immersed myself in the fashion world for the past seven plus years, I felt the need to share my love for style with a wider audience. Thus, Style Indo was born.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Our Vision</Typography>
      <Typography variant='body2' gutterBottom>Our vision is to offer a diverse and trendy collection of fashion products that cater to every style preference. Whether you're into classic elegance, contemporary chic, or bold trends, Style Indo has something for everyone.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Why Style Indo?</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Variety</Typography>
      <Typography variant='body2' gutterBottom>Explore our extensive range of fashion products, from clothing to accessories, carefully curated to keep you in vogue.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Quality</Typography>
      <Typography variant='body2' gutterBottom>We prioritize quality to ensure that each piece reflects our commitment to excellence and your satisfaction.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Convenience</Typography>
      <Typography variant='body2' gutterBottom>Shop with ease from the comfort of your home! We offer delivery services across India, bringing fashion to your doorstep.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Owner's Note</Typography>
      <Typography variant='body2' gutterBottom>Having witnessed the ever-evolving fashion landscape, I understand the importance of staying ahead of the curve. Style Indo is not just a platform; it's a reflection of my dedication to providing a seamless online shopping experience for fashion enthusiasts like you.</Typography>
      <Typography variant='body2' gutterBottom>Join us on this exciting journey, and let Style Indo be your go-to destination for all things fashion. Thank you for choosing us to be part of your style story.</Typography>
      <Typography variant='body2'>Happy Shopping!</Typography>
      <Typography variant='body2'>Priyesh Ahirwar</Typography>
      <Typography variant='body2'>Owner, Style Indo</Typography>

    </Box>

  </ThemeProvider>

  );

}

export default P1;
