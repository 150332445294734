import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home'
import MA from './MA'
import MAO from './MAO'
import MAP from './MAP'
import MAW from './MAW'
import MBW from './MBW'
import MFA from './MFA'
import MP from './MP'
import MS from './MS'
import MSW from './MSW'
import MTW from './MTW'
import WA from './WA'
import WAO from './WAO'
import WAP from './WAP'
import WAW from './WAW'
import WBW from './WBW'
import WFA from './WFA'
import WP from './WP'
import WS from './WS'
import WSW from './WSW'
import WTW from './WTW'
import P1 from './P1'
import P2 from './P2'
import P3 from './P3'
import P4 from './P4'
import P5 from './P5'
import P6 from './P6'
import P7 from './P7'
import PaymentSuccess from './PaymentSuccess';

function App() {

  return (
  
  <Routes>
    
    <Route path='/' element={<Home/>} />
    
    <Route path="/ma" element={<MA />} />

    <Route path="/mao" element={<MAO />} />

    <Route path="/map" element={<MAP />} />

    <Route path="/maw" element={<MAW />} />

    <Route path="/mbw" element={<MBW />} />

    <Route path="/mfa" element={<MFA />} />

    <Route path="/mp" element={<MP />} />

    <Route path="/ms" element={<MS />} />

    <Route path="/msw" element={<MSW />} />
            
    <Route path="/mtw" element={<MTW />} />

    <Route path="/wa" element={<WA />} />

    <Route path="/wao" element={<WAO />} />

    <Route path="/wap" element={<WAP />} />

    <Route path="/waw" element={<WAW />} />

    <Route path="/wbw" element={<WBW />} />

    <Route path="/wfa" element={<WFA />} />

    <Route path="/wp" element={<WP />} />

    <Route path="/ws" element={<WS />} />

    <Route path="/wsw" element={<WSW />} />
            
    <Route path="/wtw" element={<WTW />} />

    <Route path="/p1" element={<P1 />} />

    <Route path="/p2" element={<P2 />} />
    
    <Route path="/p3" element={<P3 />} />

    <Route path="/p4" element={<P4 />} />

    <Route path="/p5" element={<P5 />} />

    <Route path="/p6" element={<P6 />} />

    <Route path="/p7" element={<P7 />} />

    <Route path="/payment/success" element={<PaymentSuccess />} />
    
  </Routes>

  );
  
}

export default App;
