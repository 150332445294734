import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    
    apiKey: "AIzaSyDtdowZm2Aknx78gA5MXuAqsAXhwgcJMmM",
    authDomain: "style-indo-101.firebaseapp.com",
    projectId: "style-indo-101",
    storageBucket: "style-indo-101.appspot.com",
    messagingSenderId: "236419930009",
    appId: "1:236419930009:web:d64479e2966938975de508"

};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { firestore, storage };
