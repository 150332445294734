import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material'
import BackIcon from '@mui/icons-material/ArrowBack'

const theme = createTheme({

  palette: {

    primary: {

      main: '#000000',

    },

    secondary: {

      main: '#000000', light: '#FFFFFF', contrastText: '#212121',

    },

  },

});

function P5() {

  return(

  <ThemeProvider theme={theme}>

    <AppBar sx={{ position: 'fixed' }}>

      <Toolbar>

        <IconButton edge="start" color="inherit" component={Link} to="/">

          <BackIcon />

        </IconButton>

        <Typography>Legal Policy</Typography>

      </Toolbar>

    </AppBar>

    <Box margin={2}>

      <Box margin={10} />

      <Typography variant='body2' gutterBottom fontWeight={600}>Liability</Typography>
      <Typography variant='body2' gutterBottom>Style Indo is not liable for any indirect, incidental, or consequential damages.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Dispute Resolution</Typography>
      <Typography variant='body2' gutterBottom>Any disputes shall be resolved through arbitration.</Typography>

    </Box>

  </ThemeProvider>

  );

}

export default P5;
