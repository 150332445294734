import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material'
import BackIcon from '@mui/icons-material/ArrowBack'

const theme = createTheme({

  palette: {

    primary: {

      main: '#000000',

    },

    secondary: {

      main: '#000000', light: '#FFFFFF', contrastText: '#212121',

    },

  },

});

function P4() {

  return(

  <ThemeProvider theme={theme}>

    <AppBar sx={{ position: 'fixed' }}>

      <Toolbar>

        <IconButton edge="start" color="inherit" component={Link} to="/">

          <BackIcon />

        </IconButton>

        <Typography>Privacy Policy</Typography>

      </Toolbar>

    </AppBar>

    <Box margin={2}>

      <Box margin={10} />

      <Typography variant='body2' gutterBottom fontWeight={600}>Effective Date : [25/02/2024]</Typography>
      <Typography variant='body2' gutterBottom>Welcome to Style Indo! At Style Indo, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our website. By accessing or using our services, you agree to the terms outlined in this policy.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Information We Collect</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Personal Information</Typography>
      <Typography variant='body2' gutterBottom>When you create an account or make a purchase, we collect personal information such as your name, email address, phone number, and shipping address.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Transaction Data</Typography>
      <Typography variant='body2' gutterBottom>We collect information related to your transactions on our website, including purchase history, payment details, and order status.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Device and Usage Information</Typography>
      <Typography variant='body2' gutterBottom>We gather data about the devices you use to access our website, including IP addresses, browser information, and device characteristics.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Cookies</Typography>
      <Typography variant='body2' gutterBottom>Like many websites, we use cookies to enhance your browsing experience. These cookies help us analyze website traffic, customize content, and improve our services.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>How We Use Your Information</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Order Processing</Typography>
      <Typography variant='body2' gutterBottom>We use your personal information to process and fulfill your orders, communicate with you about your purchases, and provide customer support.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Account Management</Typography>
      <Typography variant='body2' gutterBottom>Your account information is used for account management, including order history, preferences, and personalized recommendations.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Communication</Typography>
      <Typography variant='body2' gutterBottom>We may use your contact information to send you updates, promotional offers, and newsletters. You can opt-out of these communications at any time.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Improving Our Services</Typography>
      <Typography variant='body2' gutterBottom>Data collected helps us analyze user preferences, improve our website's functionality, and tailor our product offerings to better suit your needs.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Information Sharing</Typography>
      <Typography variant='body2' gutterBottom>We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share your data with trusted third parties that assist us in operating our website, conducting business, or servicing you, provided those parties agree to keep this information confidential.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Data Security</Typography>
      <Typography variant='body2' gutterBottom>We implement a variety of security measures to protect your personal information. All transactions are processed through a secure gateway, and your payment information is not stored on our servers.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={300}>Your Rights</Typography>
      <Typography variant='body2' gutterBottom>You have the right to access, correct, or delete your personal information. If you have any concerns about your data, please contact us at [infostyleindo@gmail.com].</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Changes to This Policy</Typography>
      <Typography variant='body2' gutterBottom>Style Indo reserves the right to update this Privacy Policy. Any changes will be posted on this page with the revised effective date.</Typography>
      <Typography variant='body2' gutterBottom>By using our website, you consent to our Privacy Policy. If you have any questions or concerns, please contact us at [infostyleindo@gmail.com].</Typography>
      <Typography variant='body2' gutterBottom>Thank you for trusting Style Indo with your information.</Typography>
      <Typography variant='body2' gutterBottom>Priyesh</Typography>
      <Typography variant='body2' gutterBottom>Owner, Style Indo</Typography>

    </Box>

  </ThemeProvider>

  );

}

export default P4;
