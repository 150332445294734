import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material'
import BackIcon from '@mui/icons-material/ArrowBack'

const theme = createTheme({

  palette: {

    primary: {

      main: '#000000',

    },

    secondary: {

      main: '#000000', light: '#FFFFFF', contrastText: '#212121',

    },

  },

});

function P7() {

  return(

  <ThemeProvider theme={theme}>

    <AppBar sx={{ position: 'fixed' }}>

      <Toolbar>

        <IconButton edge="start" color="inherit" component={Link} to="/">

          <BackIcon />

        </IconButton>

        <Typography>Return Policy</Typography>

      </Toolbar>

    </AppBar>

    <Box margin={2}>

      <Box margin={10} />

      <Typography variant='body2' gutterBottom fontWeight={600}>Return Time Frame</Typography>
      <Typography variant='body2' gutterBottom>Customers can initiate returns within 1 day of receiving the product.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Refund Time Frame</Typography>
      <Typography variant='body2' gutterBottom>Eligible refunds will be processed within 3 days.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Eligibility for Returns</Typography>
      <Typography variant='body2' gutterBottom>Only products with sizing issues or damages are eligible for return.</Typography>
      <Typography variant='body2' gutterBottom fontWeight={600}>Unboxing Video Requirement</Typography>
      <Typography variant='body2' gutterBottom>A mandatory unboxing video is required for all return requests.</Typography>

    </Box>

  </ThemeProvider>

  );

}

export default P7;
